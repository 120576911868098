import beneficio1 from "../../iconos/save-time.png"
import beneficio2 from "../../iconos/autorizado.png"
import beneficio3 from "../../iconos/presentation.png"
import beneficio4 from "../../iconos/decision.png"
import beneficio5 from "../../iconos/avoid.png"
import beneficio6 from "../../iconos/graphic-design.png"

export default [
  {
    id: 1,
    titulo: 'Ahorrar más tiempo en los trabajos de grado',
    icono: beneficio1
  },
  {
    id: 2,
    titulo: 'Facilitar procesos de acreditación',
    icono: beneficio2
  },
  {
    id: 3,
    titulo: 'Obtener informes con reportes e indicadores',
    icono: beneficio3
  },
  {
    id: 4,
    titulo: 'Apoyar la toma de decisiones con datos',
    icono: beneficio4
  },

  {
    id: 5,
    titulo: 'Evitar realizar reprocesos 2 o 3 veces',
    icono: beneficio5
  },
  {
    id: 6,
    titulo: 'Medir en segundos el avance de las tesis',
    icono: beneficio6
  },
];
