import './css/TablaInterna.css';
import React from 'react';
import ToolTipIcono from './ToolTipIcono';
import ToolTipImagen from './ToolTipImagen';


const TablaInterna = ({ informacion }) => {
    return (
        <div className="table-responsive">
            <table className="table tablaComparativa" id="tablaInterna">
                <thead>
                    <tr>
                        <th className="celdaCabeza" key={"thCaracteristica"}>
                            <span className="celdaCabezaLetra">Característica</span>
                        </th>
                        {informacion.logos.map(logo => (
                            <th className="celdaCabeza" key={logo.id + logo.imagen}>
                                <ToolTipImagen logo={logo} />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="celdaCuerpoCaracteristicas celdaCuerpoLetra" key={"tdConfigurable"}>
                            <div className='contenedorPalabraIcono'>
                                <span className="fraseCaracteristicas">
                                    Configurable a la universidad
                                </span>
                                <ToolTipIcono informacion="El sistema se adapta un 100% al proceso de cada modalidad de trabajo de grado o tesis de la universidad, no reemplazamos tu proceso por uno nuevo" />
                            </div>
                        </td>
                        {informacion.logos.map(logo => (
                            <td className="celdaCuerpo" key={logo.id + logo.imagen}>
                                <img className="imagenIcono" title="" src={logo.configurable} id={logo.id + "configurable"} alt="icono configurable" />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="celdaCuerpoCaracteristicas celdaCuerpoLetra" key={"tdIntegracionDeLosActores"}>
                            <div className='contenedorPalabraIcono'>
                                <span className="fraseCaracteristicas">
                                    Integración de todos los actores
                                </span>
                                <ToolTipIcono informacion="Una sola plataforma donde pueden interactuar diferentes tipos de usuarios: estudiantes, profesores, directores, evaluadores externos, secretarias y monitores" />
                            </div>
                        </td>
                        {informacion.logos.map(logo => (
                            <td className="celdaCuerpo" key={logo.id + logo.imagen}>
                                <img className="imagenIcono" title="" src={logo.integracionDeLosActores} id={logo.id + "integracionDeLosActores"} alt="icono configurable" />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="celdaCuerpoCaracteristicas celdaCuerpoLetra" key={"tdRegistroDeInformacion"}>
                            <div className='contenedorPalabraIcono'>
                                <span className="fraseCaracteristicas">
                                    Registro de información autogestionable
                                </span>
                                <ToolTipIcono informacion="Cada tipo de usuario registra la información concerniente a su propio proceso, e.g. cargar el documento de tesis (estudiante), evaluar tesis (evaluadores), asignar jurados (directores de programa)" />
                            </div>
                        </td>
                        {informacion.logos.map(logo => (
                            <td className="celdaCuerpo" key={logo.id + logo.imagen}>
                                <img className="imagenIcono" title="" src={logo.registroDeInformacion} id={logo.id + "registroDeInformacion"} alt="icono configurable" />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="celdaCuerpoCaracteristicas celdaCuerpoLetra" key={"tdSistematizacion"}>
                            <div className='contenedorPalabraIcono'>
                                <span className="fraseCaracteristicas">
                                    Sistematización del proceso
                                </span>
                                <ToolTipIcono informacion="El proceso de trabajo de grado es transparente para todos, permite la organización del proceso de trabajo de grado alineado con la universidad de una manera transparente para todos" />
                            </div>
                        </td>
                        {informacion.logos.map(logo => (
                            <td className="celdaCuerpo" key={logo.id + logo.imagen}>
                                <img className="imagenIcono" title="" src={logo.sistematizacion} id={logo.id + "sistematizacion"} alt="icono configurable" />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="celdaCuerpoCaracteristicas celdaCuerpoLetra" key={"tdIndicadores"}>
                            <div className='contenedorPalabraIcono'>
                                <span className="fraseCaracteristicas">
                                    Generación de indicadores
                                </span>
                                <ToolTipIcono informacion="Genera indicadores y reportes de interés en segundos" />
                            </div>
                        </td>
                        {informacion.logos.map(logo => (
                            <td className="celdaCuerpo" key={logo.id + logo.imagen}>
                                <img className="imagenIcono" title="" src={logo.indicadores} id={logo.id + "indicadores"} alt="icono configurable" />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="celdaCuerpoCaracteristicas celdaCuerpoLetra" key={"tdTrazabilidad"}>
                            <div className='contenedorPalabraIcono'>
                                <span className="fraseCaracteristicas">
                                    Trazabilidad
                                </span>
                                <ToolTipIcono informacion="Conoce en tiempo real el estado de cada trabajo de grado de tus estudiantes" />
                            </div>
                        </td>
                        {informacion.logos.map(logo => (
                            <td className="celdaCuerpo" key={logo.id + logo.imagen}>
                                <img className="imagenIcono" title="" src={logo.trazabilidad} id={logo.id + "trazabilidad"} alt="icono configurable" />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="celdaCuerpoCaracteristicas celdaCuerpoLetra" key={"tdNotificacionesAutomaticas"}>
                            <div className='contenedorPalabraIcono'>
                                <span className="fraseCaracteristicas">
                                    Notificaciones automáticas
                                </span>
                                <ToolTipIcono informacion="Genera notificaciones automáticas para realizar acciones (e.g. revisiones, aprobaciones) y según fechas límite (e.g. recordar a los profesores y jurados la evaluación de una tesis)" />
                            </div>
                        </td>
                        {informacion.logos.map(logo => (
                            <td className="celdaCuerpo" key={logo.id + logo.imagen}>
                                <img className="imagenIcono" title="" src={logo.notificaiconesAutomaticas} id={logo.id + "notificacionesAutomaticas"} alt="icono configurable" />
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TablaInterna