//import funcion1 from "../../iconos/funcion1.png"
//import funcion3 from "../../iconos/funcion3.png"
//import funcion6 from "../../iconos/funcion6.png"
import funcion2 from "../../iconos/funcion2.png"
import funcion4 from "../../iconos/funcion4.png"
import funcion5 from "../../iconos/funcion5.png"

export default [
  {
    id: 1,
    titulo: 'Configuramos la plataforma ',
    texto: 'Ajustamos el sistema al proceso de trabajos de grado de tu universidad, es decir el sistema se adapta a ti',
    icono: funcion2,
    agregar_fila: true
  },
  {
    id: 2,
    titulo: 'Cargamos proyectos y tesis de grado ',
    texto: 'Cargamos trabajos de grado y tesis de los estudiantes actuales de los programas académicos',
    icono: funcion4
  },
  {
    id: 3,
    titulo: 'Capacitación e interacción de usuarios ',
    texto: 'Capacitamos a todos los usuarios del sistema y están listos para interactuar con este',
    icono: funcion5
  }
];
