import './css/NuestrosClientes.css';
import React from 'react';
import TarjetaNuestrosClientes from './TarjetaNuestrosClientes';
import datos from './datos';


const NuestrosClientes = () => {
    return (

        <section id="nuestrosClientes" className="margenNuestrosClientes seccionBackgroundNuestrosClientes">
            <div className="contenedor container">
                <div className="row justify-content-center section-heading mb-3">
                    <div className="col-lg-8 col-xl-7 text-center">
                        <h2 className="tituloNuestrosClientes">Nuestros clientes</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {datos.map(bloque => (
                        <TarjetaNuestrosClientes informacion={bloque} key={bloque.id} />
                    ))}
                </div>
            </div>
        </section>

    );
}

export default NuestrosClientes