import './css/InformacionEmpresa.css';
import React from 'react';


const Fila = ({ informacion }) => {
    if (informacion.icono) {
        return (
            <li>
                <a href="/#" className="media">
                    <div className="only-icon only-icon-sm rounded-circle">
                        <i className={informacion.icono}></i>
                    </div>
                    <span className="mediosContactoInformacionEmpresa">
                        {informacion.texto}
                    </span>
                </a>
            </li>
        );
    } else {
        return (
            <li>
                <a href="/#">{informacion.texto}</a>
            </li>
        );
    }

}

const TarjetaEmpresa = ({ informacion }) => {

    return (
        <div className="col-sm-2 offset-md-7 bloqueInformacionEmpresa">
            <h2 className="tituloContactoInformacionEmpresa">{informacion.titulo}</h2>
            <ul className="list-unstyled dark-link footer-links">
                {informacion.enlaces.map(fila => (
                    <Fila informacion={fila} key={fila.id} />
                ))}
            </ul>

        </div>
    );

}

export default TarjetaEmpresa