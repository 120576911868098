import './css/TarjetaBeneficios.css';
import React from 'react';


const TarjetaBeneficios = ({ informacion }) => {

    return (
        <div className="col-sm-6 col-lg-4 contenedorTarjetasBeneficios">
            <div className="card bg-white rounded-style mx-1 hover-top shadow">
                <div className="card-body text-center">
                    <div className="icon icon-xl contenedorNumeroTarjetaBeneficios">
                        <img title="" src={informacion.icono} alt={"iconoBeneficio" + informacion.id}></img>
                    </div>
                    <h3 className="tituloTarjetaBeneficios">{informacion.titulo}</h3>
                </div>
            </div>
        </div>
    );

}

export default TarjetaBeneficios