import './css/Inicio.css';
import React from 'react';
import Fomurlario1 from './Formulario1';

const Inicio = () => {

    return (
        <section id="inicio" className="effect-section skew-effect primary-after">
            <div className="white-shade-effect"></div>
            <div className="container position-relative py-lg-6 z-index-9">
                <div className="row justify-content-center py-3">
                    <div className="col-lg-10 col-xl-8 text-center text-white pb-5">
                        <br />
                        <h2 className="display-8 pb-2">Solicita tu demo</h2>
                        <h3>Tu aliado que monitorea y optimiza la gestión de trabajos de grado.</h3>
                        <br />
                        <div className="px-3 py-2 bg-white rounded shadow-style">
                            <Fomurlario1 />
                        </div>
                        <div className="contenedorPoliticas">
                            <span className='tamanhoLetraPolitica'>
                                Acepto que Danalytics registre los datos anteriores y me proporcione información sobre servicios y productos por correo electrónico.
                                Al enviar este formulario, aceptas el tratamiento de tus datos personales de acuerdo con nuestra política de privacidad.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Inicio