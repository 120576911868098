import './css/Testimonios.css';
import React from 'react';
import TarjetaTestimonio from './TarjetaTestimonio';
import datos from './datos'


const Testimonios = () => {
    const textoBotonTestimonios = "Agenda una cita";

    const handleClick = () => {
        const referenciaInicio = document.getElementById('inicio');
        const origen = document.getElementById('origen');

        referenciaInicio.scrollIntoView({behavior: 'smooth'});
        origen.value = textoBotonTestimonios;
    };

    return (

        <section id="testimonios" className="seccionBackgroundTestimonios">
            <div className="margenTestimonios contenedor container">
                <div className="row justify-content-center section-heading mb-3">
                    <div className="col-lg-8 col-xl-7 text-center">
                        <h2 className="tituloTestimonios">Los decanos y directores de departamento confían en nosotros</h2>
                    </div>
                </div>
                <div className="row justify-content-center contenedorTarjetasTestimonios">
                    {datos.map(bloque => (
                        <TarjetaTestimonio informacion={bloque} key={bloque.id} />
                    ))}
                </div>
                <div className="my-1 mt-5 contenedorBotonTestimonios">
                    <button id="botonTestimonios" onClick={handleClick} className="btn btn-da w-40 botonTestimonios">{textoBotonTestimonios}</button>
                </div>
            </div>
        </section>

    );
}

export default Testimonios