import './css/RedesBarraLateral.css';
import React from 'react';
import datos from '../datosGenerales';
import ToolTipRedes from "./ToolTipRedes";


const RedesBarraLateral = () => {
    return (
        <section id="redesBarraLateral" className="barraLateralPermanente">
            {datos.map(redes => (
                <div className="col-lg-6 my-3 contenedorRedes" key={redes.id}>
                    <a href={redes.redireccion} target="_blank" rel='noopener noreferrer nofollow'>
                        <ToolTipRedes redes={redes} />
                    </a>
                </div>
            ))}
        </section>
    );
}

export default RedesBarraLateral