import './css/FooterTerminos.css';
import React from 'react';
import datos from './datos';


const FooterTerminos = () => {

    return (
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 my-1">
                    <ul className="nav justify-content-center justify-content-md-start dark-link small footer-link-1">
                        <li className="terminosYCondiciones"><a href="/#">Términos y condiciones de uso</a></li>
                        <li className="terminosYCondiciones"><a href="/#">Política de  privacidad y tratamiento de datos</a></li>
                    </ul>
                </div>
                <div className="col-md-6 my-1 text-center text-md-end small">
                    <p className="copyRight">© {datos.copyright.anho} Copyright <a href="#home" className="text-reset">DAnalytics SAS</a></p>
                </div>
            </div>
        </div>
    );

}

export default FooterTerminos