import './css/TarjetaTestimonio.css';
import React from 'react';

const TarjetaTestimonio = ({ informacion }) => {

    return (
        <div className="col-lg-4 col-sm-6 tarjetaTestimonio contenedorTarjetasTestimonios">
            <div className="carta card bg-white rounded-style mx-1 hover-top shadow">
                <div className="cuerpo card-body text-center">
                    <div className="cajaIlustracion">
                        <img title="" src={informacion.imagen} alt={"foto testimonio" + informacion.id} />
                    </div>
                    <div>
                        <span className="descripcion">"{informacion.info}"</span>
                    </div>
                    <div className='contenedorNombreTestimonio'>
                        <span className="nombreTestimonio">{informacion.nombre}</span>
                    </div>
                    <div className='contenedorCargo'> 
                        <span className="cargo">-{informacion.cargo}</span>
                    </div>
                    {/* 
                    <div className="contenedorBoton">
                        <button className="btn btn-primary botonTestimonio">
                                VER LA HISTORIA
                        </button>
                    </div>
                    */}
                </div>
            </div>
        </div>
    );

}

export default TarjetaTestimonio