import './css/Funciones.css';
import React from 'react';
import Tajeta from './TarjetaSinBoton';
import datos from './datos'


const Funciones = () => {
    return (
        <section id="funciones" className="seccionBackgroundFunciones">
            <div className="margenFunciones container">
                <div className="row justify-content-center section-heading mb-3">
                    <div className="col-lg-8 col-xl-7 text-center">
                        <h2 className="tituloFunciones">¿Cómo funciona DAnalytics Education?</h2>
                    </div>
                </div>
                <div className="row">
                    {datos.map(bloque => (
                        <Tajeta informacion={bloque} key={bloque.id} />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Funciones
