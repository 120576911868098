import './css/ToolTipImagen.css';
import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const ToolTipImagen = ({ logo }) => {
    const renderTooltip = props => (
        <Tooltip {...props}>{logo.mensaje}</Tooltip>
    );

    return (

        <OverlayTrigger placement="top" overlay={renderTooltip}>
            <img className="imagenLogo" title="" src={logo.imagen} key={logo.id} alt= {"imagen comparativa" + logo.id} />
        </OverlayTrigger>

    );
}

export default ToolTipImagen