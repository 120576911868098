import './css/TarjetaImagen.css';
import React from 'react';
import Imagen1 from '../../imagenes/IMAGENES-DA-EDUCATION-2.png'
import Imagen2 from '../../imagenes/IMAGENES-DA-EDUCATION-1.png'


const Derecha = ({ informacion }) => {
    return (
        <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-xl-5 my-3">
                <h1 className="tituloTarjetaImagen">Mejora el proceso de los trabajos de grado en tu universidad</h1>
                <h2 className="subtituloTarjetaImagen">{informacion.titulo_2}</h2>
            </div>
            <div className="col-lg-6 my-3">
                {/*
                    <div className="row contenedorVideo">
                        <div>
                            <iframe className="videoCentrado container"
                                width={informacion.ancho}
                                height={informacion.alto}
                                src={`https://www.youtube.com/embed/${informacion.embedId}`}
                                title={informacion.titulo}
                                frameBorder={informacion.frameBorder}
                                allow={informacion.permitir}
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                */}

                {/*
                    <img className="img-lg-140 imagenPortada" src={informacion.imagen} alt="servicios" />
                */}
            </div>
        </div>
    );
}

const Izquierda = ({ informacion }) => {
    const imagenes = [Imagen1, Imagen2]
    return (
        <div className="row align-items-center justify-content-between flex-row-reverse">
            <div className="col-lg-6 col-xl-5 my-3">
                <h2 className="mb-4">{informacion.titulo_2}</h2>
                <p className="lead mb-5">{informacion.texto}</p>
            </div>
            <div className="col-lg-6 my-3">
                <img className="img-lg-140 float-lg-end" title="" src={imagenes[informacion.imagen]} alt="servicios" />
            </div>
        </div>
    );
}

const TajetaImagen = ({ informacion }) => {

    return (
        <div id={`caracteristicas${informacion.id}`} className="section effect-section py-0">
            <div className="container margenContenedorInicio">
                {informacion.derecha
                    ? (
                        <Derecha informacion={informacion} />
                    )
                    : (
                        <Izquierda informacion={informacion} />
                    )
                }
            </div>
        </div>
    );

}

export default TajetaImagen