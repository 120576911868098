import './css/ToolTipRedes.css';
import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const ToolTipRedes = ({ redes }) => {
    const renderTooltip = props => (
        <Tooltip {...props}>{redes.mensaje}</Tooltip>
    );

    return (

        <OverlayTrigger placement="left" overlay={renderTooltip}>
            <img className="dimensionesRedes" src={redes.logo} title="" key={redes.id} alt= {"logo redes" + redes.id} />
        </OverlayTrigger>

    );
}

export default ToolTipRedes