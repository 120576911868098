import ImagenDefault from "../../iconos/profile.png"
import EstebanRosero from "../../imagenes/Esteban_Emilio_Rosero.png"
import JoseMiguelRamirezScarpetta from "../../imagenes/JoseMiguelRamirezScarpetta.png"
//import Ferley from "../../imagenes/Ferley.png"

export default [
  {
    id: 1,
    nombre: 'Esteban Emilio Rosero García',
    cargo: 'Director de posgrados de la Escuela de Ingeniería Eléctrica y Electrónica',
    info: 'El sistema de gestión de tesis ayuda a realizar un seguimiento más minucioso del proceso de preparación y desarrollo de los proyectos o tesis de cada uno de los estudiantes, así como seguir la línea de tiempo en el cual se han desarrollado las actividades, nos ayuda a sacar estadísticas y a tomar decisiones. Con esta plataforma eliminamos totalmente los procesos en papel.',
    imagen: EstebanRosero,
  },
  {
    id: 2,
    nombre: '',
    cargo: 'Director de la Escuela de Ingeniería Eléctrica y Electrónica de la Universidad del Valle',
    info: 'El sistema me gusta mucho, puesto que permite mantener un control y trazabilidad completa de la información de nuestros trabajos de grado, evitando así problemas de repetición de información o de envío de documentos',
    imagen: ImagenDefault,
  },
  {
    id: 3,
    nombre: 'José Miguel Ramírez Scarpetta',
    cargo: '',
    info: 'Para mí ha sido un sistema de mucha utilidad, usualmente tengo la dirección de una docena de estudiantes, de pregrado, maestría y doctorado; tenerlos integrados en una sola plataforma me facilita mucho el seguimiento de sus trabajos, con los requerimientos de proceso para cada nivel de formación. ',
    imagen: JoseMiguelRamirezScarpetta,
  }
];
