import './css/Universidades.css';
import React from 'react';
import TarjetaUniversidad from './TarjetaUniversidad';
import datos from './datos';
import datosGenerales from '../datosGenerales';


const Universidades = () => {
    return (

        <section id="universidades" className="margenUniversidades seccionBackgroundUniversidades">
            <div className="contenedor container">
                <div className="row justify-content-center section-heading mb-3">
                    <div className="col-lg-8 col-xl-7 text-center">
                        <h2 className="tituloUniversidades">Universidades que usan Danalytics Education</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {datos.map(bloque => (
                        <TarjetaUniversidad informacion={bloque} key={bloque.id} />
                    ))}
                </div>
                <div className="my-1 mt-5 contenedorBotonUniversidades">
                    <a className="referenciaBotonUniversidades" href={datosGenerales[0].redireccion} target="_blank" rel='noopener noreferrer nofollow'>
                        <button className="btn btn-da w-100 botonUniversidades">
                            Contáctanos
                        </button>
                    </a>
                </div>
            </div>
        </section>

    );
}

export default Universidades