import './css/TarjetaNuestrosClientes.css';
import React from 'react';


const TarjetaNuestrosClientes = ({ informacion }) => {

    return (
        <div className="col-lg-4 col-sm-6 tarjetaNuestrosClientes">
            <div className="carta">
                <div className="cuerpo">
                    <div className="cajaIlustracion">
                        <img title="" src={informacion.imagen} alt={"logoNuestrosClientes" + informacion.id} />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TarjetaNuestrosClientes