import React from 'react';
import Funciones from '../../componentes/Funciones/Funciones';
//import Planes from '../../componentes/Planes/Planes';
//import Nosotros from '../../componentes/Nosotros/Nosotros';
import Empresa from '../../componentes/Empresa/Empresa';
import Inicio from '../../componentes/Inicio/Inicio';
import FooterTerminos from '../../componentes/FooterTerminos/FooterTerminos';
import Header from '../../componentes/Header/Header';
import InicioNuevo from '../../componentes/InicioNuevo/InicioNuevo';
import Universidades from '../../componentes/Universidades/Universidades';
import TablaComparativa from '../../componentes/TablaComparativa/TablaComparativa';
import Testimonios from '../../componentes/Testimonios/Testimonios';
import ResumenEstadisticas from '../../componentes/ResumenEstadisticas/ResumenEstadisticas';
//import VideoMuestra from '../../componentes/VideoMuestra/VideoMuestra';
import Beneficios from '../../componentes/Beneficios/Beneficios';
//import ContactoWhatsapp from '../../componentes/ContactoWhatsapp/ContactoWhatsapp';
import RedesBarraLateral from '../../componentes/RedesBarraLateral/RedesBarraLateral';
import NuestrosClientes from '../../componentes/NuestrosClientes/NuestrosClientes';


const Landing = () => {

    return (
        <div>
            <div id="loading" className="preloader">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <Header />
            <main>
                <InicioNuevo />
                <ResumenEstadisticas />
                <Beneficios />
                <TablaComparativa />
                <Funciones />
                <Universidades />
                <Testimonios />
                <RedesBarraLateral />
                <NuestrosClientes />
                <Inicio />
            </main>
            <footer className="footer">
                <div>
                    <Empresa />
                </div>
                <div className="footer-bottom border-top py-3">
                    <FooterTerminos />
                </div>
            </footer>
        </div>
    );
};

export default Landing;
