import Univalle from "../../imagenes/Univalle.png"
import UNal from "../../imagenes/UniversidadNacional.png"

export default [
  {
    id: 1,
    nombre: 'Universidad Nacional de Colombia',
    premios: [
      { 
        id: 1,
        descripcion: '#1 a nivel nacional en el ranking Sapiens 2022-1'
      },
      { 
        id: 2,
        descripcion: '#10 de QS Latin America University Rankings 2022'
      }
    ],
    imagen: UNal,
  },
  {
    id: 2,
    nombre: 'Universidad del Valle',
    premios: [
      { 
        id: 1,
        descripcion: '#3 a nivel nacional en el ranking Sapiens 2022-1'
      },
      { 
        id: 2,
        descripcion: '#50 de QS Latin America University Rankings 2022'
      }
    ],
    imagen: Univalle,
  }
];
