import Logo from "../../imagenes/logo-education.png"
import LogoGmail from "../../imagenes/gmail.png"
import LogoMoodle from "../../imagenes/Moodle.png"
import LogoDrive from "../../imagenes/drive.png"
//import LogoFile from "../../imagenes/file.png"
//import LogoExcel from "../../imagenes/excel.png"
import Check from "../../iconos/check.png"
import DeleteButton from "../../iconos/delete-button.png"
import Pause from "../../iconos/pause.png"

export default {
    "logos": [
        {
            id: 1,
            imagen: LogoGmail,
            configurable: DeleteButton,
            integracionDeLosActores: Check,
            registroDeInformacion: DeleteButton,
            sistematizacion: Pause,
            indicadores: DeleteButton,
            trazabilidad: DeleteButton,
            notificaiconesAutomaticas: DeleteButton,
            mensaje: "Uso de correo electrónico para la gestión de los trabajos de grado y tesis"
        },
        {
            id: 2,
            imagen: LogoMoodle,
            configurable: Pause,
            integracionDeLosActores: Check,
            registroDeInformacion: Check,
            sistematizacion: Pause,
            indicadores: DeleteButton,
            trazabilidad: Pause,
            notificaiconesAutomaticas: DeleteButton,
            mensaje: "Uso de sistemas LMS (e.g. Moodle, Blackboard, Canvas) para la gestión de los trabajos de grado y tesis"
        },
        {
            id: 3,
            imagen: LogoDrive,
            configurable: DeleteButton,
            integracionDeLosActores: DeleteButton,
            registroDeInformacion: DeleteButton,
            sistematizacion: Pause,
            indicadores: Pause,
            trazabilidad: Pause,
            notificaiconesAutomaticas: DeleteButton,
            mensaje: "Uso del servicio de almacenamiento en la nube (e.g. Google Drive) para gestión de los documentos relacionados a trabajos de grado y tesis"
        },
        {
            id: 4,
            imagen: Logo,
            configurable: Check,
            integracionDeLosActores: Check,
            registroDeInformacion: Check,
            sistematizacion: Check,
            indicadores: Check,
            trazabilidad: Check,
            notificaiconesAutomaticas: Check,
            mensaje: "Danalytics Education"
        }
    ]
};