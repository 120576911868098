import './css/TarjetaSinBoton.css';
import React from 'react';


const Tajeta = ({ informacion }) => {

    return (
        <div className="col-sm-6 col-lg-4 contenedorTarjetas">
            <div className="card bg-white rounded-style mx-1 hover-top shadow">
                <div className="card-body text-center">
                    <div className="icon icon-xl contenedorNumeroTarjeta">
                        <img src={informacion.icono} title="" alt={"iconoFuncion" + informacion.id}></img>
                    </div>
                    <h3 className="tituloTarjeta">{informacion.id}. {informacion.titulo}</h3>
                    <p className="w-lg-95 descripcionTarjeta">{informacion.texto}</p>
                    {informacion.agregar_fila ? <br /> : ''}
                </div>
            </div>
        </div>
    );

}

export default Tajeta