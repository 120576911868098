import ImagenInicio from '../../imagenes/graduacion.jpg'

export default [
  {
    id: 1,
    derecha: true,
    titulo_1: 'Monitoreo y análisis',
    titulo_2: 'Con Danalytics Education ahorra tiempo en la gestión de tesis y consolida toda la información que necesitas.',
    texto: 'Encuentra fácilmente qué está pasando con los trabajos de grado, cuántos matricularon, cancelaron y aprobaron, y responde decenas de preguntas adicionales en segundos.',
    titulo_3: 'Crecimiento del e-commerce en Colombia',
    enlace: 'https://danalyticspro.co/2021/07/07/oportunidades-para-el-ecommerce-en-colombia/',
    texto_enlace: 'Ver más',
    imagen: ImagenInicio,

    ancho: "852",
    alto: "480",
    titulo: "Univalle, la institución de educación superior de los vallecaucanos",
    frameBorder: "0",
    permitir: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    embedId: "HRlvSRrgEfw"
  }
];
