import './css/TablaComparativa.css';
import React from 'react';
import datos from './datos'
import TablaInterna from './TablaInterna';
import Check from "../../iconos/check.png"
import DeleteButton from "../../iconos/delete-button.png"
import Pause from "../../iconos/pause.png"


const TablaComparativa = () => {
    return (

        <section id="tablaComparativa" className="seccionBackgroundTablaComparativa">
            <div className="margenTablaComparativa contenedor">
                <div className="row justify-content-center section-heading mb-3 contenedorTitulo">
                    <div className="col-lg-8 col-xl-7 text-center">
                        <h2 className="tituloVideo">Danalytics Education vs otras herramientas</h2>
                    </div>
                </div>
                <TablaInterna informacion={datos} />
                <div className="contenedorResumen">
                    <img className="imagenDetalleIcono" title="" src={Check} alt="detalle check"></img>
                    Tiene
                    <img className="imagenDetalleIcono" title="" src={Pause} alt="detalle pause"></img>
                    Parcialmente
                    <img className="imagenDetalleIcono" title="" src={DeleteButton} alt="detalle deleteButton"></img>
                    No tiene
                </div>
            </div>
        </section>

    );
}

export default TablaComparativa