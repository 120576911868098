import './css/ResumenEstadisticas.css';
import React from 'react';
import TarjetaEstadistica from './TarjetaEstadistica';
import datos from './datos'


const ResumenEstadisticas = () => {
    return (

        <section id="resumenEstadisticas" className="resumenEstadisticas">
            <div className="contenedor">
                <div className="row">
                    {datos.map(bloque => (
                        <TarjetaEstadistica informacion={bloque} key={bloque.id} />
                    ))}
                </div>
            </div>
        </section>

    );
}

export default ResumenEstadisticas