import './css/Beneficios.css';
import React from 'react';
import TarjetaBeneficios from './TarjetaBeneficios';
import datos from './datos'


const Beneficios = () => {
    const textoBotonBeneficios = "Agenda tu demo";

    const handleClick = () => {
        const referenciaInicio = document.getElementById('inicio');
        const origen = document.getElementById('origen');

        referenciaInicio.scrollIntoView({behavior: 'smooth'});
        origen.value = textoBotonBeneficios;
    };

    return (
        <section id="servicios" className="margenBeneficios seccionBackgroundBeneficios">
            <div className="container">
                <div className="row justify-content-center section-heading mb-3">
                    <div className="col-lg-8 col-xl-7 text-center">
                        <h2 className="tituloBeneficios">6 Beneficios de usar Danalytics Education</h2>
                    </div>
                </div>
                <div className="row">
                    {datos.map(bloque => (
                        <TarjetaBeneficios informacion={bloque} key={bloque.id} />
                    ))}
                </div>
                <div className="my-1 mt-5 contenedorBotonBeneficios">
                    <button id="botonBeneficios" onClick={handleClick} className="btn btn-da w-40 botonBeneficios">{textoBotonBeneficios}</button>
                </div>
            </div>
        </section>
    );
}

export default Beneficios
