import './css/Formulario1.css';
import React from 'react';
//import ReactGA from 'react-ga';


const Fomulario1 = () => {

    /*const eventoClick = (categoria, accion) => {
       
        ReactGA.event({
            category: categoria,
            action: accion
        });
    }*/
    return (
        <form id="formulario1">
            <div className="row justify-content-center">
                <div className="col-md-5 my-1 textoCampos">
                    <span>Nombre:</span>
                    <input name="nombre" id="nombre" className="form-control" placeholder="Nombre" type="text" />
                </div>
                <div className="col-md-5 my-1 textoCampos">
                    <span>Correo Corporativo:</span>
                    <input name="correo" id="correo" className="form-control" placeholder="Correo Corporativo" type="email" required />
                </div>
                <div className="col-md-5 my-1 textoCampos">
                    <span>Celular:</span>
                    <input name="celular" id="celular" className="form-control" placeholder="Celular" type="text" required />
                </div>
                <div className="col-md-5 my-1 textoCampos">
                    <span>Tipo Contacto:</span>
                    <select className="form-control flechaSelect" name="selectTipoPersona" required>
                        <option value="Eres profesor">Eres profesor</option>
                        <option value="Eres decano">Eres decano</option>
                        <option value="Eres estudiante">Eres estudiante</option>
                    </select>
                </div>
                <div className="col-md-5 my-1 textoCampos campoOrigen">
                    <input name="origen" id="origen" className="form-control" placeholder="Origen" type="text" />
                </div>
                <div className="col-md-5 my-3 contenedorBotonCentrado">
                    <button className="btn btn-da w-100">Quiero más información</button>
                </div>
            </div>
        </form>
    );

}

export default Fomulario1