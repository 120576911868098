import React from 'react';
import Landing from '../Landing/index';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lfr0zscAAAAAK4AwvBRU808TKlP9-Mci5FdItxy">
      <Landing />
    </GoogleReCaptchaProvider>
  );
}

export default App;
