import './css/Empresa.css';
import React from 'react';
import TarjetaEmpresa from './InformacionEmpresa';
import datos from './datos';
import Logo from '../../imagenes/Logo-Danalytics.png';


const Empresa = () => {
    return (
        <div id="footer_empresa" className="container margenEmpresa">
            <div className="row">
                <div className="col-lg-3 my-4">
                    <img className="imagenFooter container" src={Logo} title="" alt="logo Danalytics" />
                    <p className="detallesEmpresa w-lg-80"><a href={datos.empresa.titulo} rel='noopener noreferrer'>{datos.empresa.titulo_ver}</a></p>
                    <div className="nav justify-content-center justify-content-md-start pt-1">
                        {datos.empresa.redes.map(red_social => (
                            <a key={red_social.id} className="icon icon-sm icon-primary rounded me-2" href={red_social.link} rel='noopener noreferrer nofollow'>
                                <i className={red_social.icono}></i>
                            </a>
                        ))}
                    </div>
                    <a className="detallesEmpresa" href={datos.sic.enlace} rel='noopener noreferrer nofollow'>{datos.sic.enlace_ver}</a>
                </div>

                {datos.informacion.map(bloque => (
                    <TarjetaEmpresa informacion={bloque} key={bloque.id} />
                ))}

            </div>
        </div>
    );
}

export default Empresa