export default {
    empresa: {
        titulo: 'https://danalyticspro.co',
        titulo_ver: 'danalyticspro.co',
        logo: '/images/imagotipo180x50.png',
        redes: [
            {
                id: 1,
                icono: 'fab fa-facebook-f',
                link: 'https://www.facebook.com/DAnalyticspro'
            },
            {
                id: 2,
                icono: 'fab fa-linkedin-in',
                link: 'https://www.linkedin.com/company/danalytics/'
            },
        ]
    },
    informacion: [
        {
            id: 4,
            titulo: 'Contáctenos',
            enlaces: [
                {
                    id: 16,
                    icono:'bi bi-map',
                    texto: 'Cali, Colombia'
                },
                {
                    id: 17,
                    icono:'bi bi-envelope',
                    texto: 'comercial@danalyticspro.co'
                },
                {
                    id: 18,
                    icono:'bi bi-phone',
                    texto: '3046833625'
                }
            ]   
        },
    ],
    sic: {
        enlace: 'https://www.sic.gov.co',
        enlace_ver: 'www.sic.gov.co'
    },
};