import './css/InicioNuevo.css';
import React from 'react';
import TajetaImagen from './TarjetaImagen';
import datos from './datos'


const InicioNuevo = () => {
    return (
        <section id="inicioNuevo" className="effect-section backgroundInicio">
            {datos.map(bloque => (
                <TajetaImagen informacion={bloque} key={bloque.id} />
            ))}

        </section>


    );
}

export default InicioNuevo