import './css/ToolTipIcono.css';
import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const ToolTipIcono = ({ informacion }) => {
    const renderTooltip = props => (
        <Tooltip {...props}>{informacion}</Tooltip>
    );

    return (

        <OverlayTrigger placement="right" overlay={renderTooltip}>
            <div className="contenedorIcono">
                ?
            </div>
        </OverlayTrigger>

    );
}

export default ToolTipIcono