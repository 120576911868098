import UnivalleEscuelaIngenieriaElectricaElectronica from "../../imagenes/UnivalleEscuelaIngenieriaElectricaElectronica.png"
import UnivalleEscuelaIngenieriaIndustrial from "../../imagenes/UnivalleEscuelaIngenieriaIndustrial.png"
import UNal from "../../imagenes/UniversidadNacional.png"
import Celsia from "../../imagenes/Celsia.png"
import BancoDeLaRepublicaColombia from "../../imagenes/BancoDeLaRepublicaColombia.png"
import Sidoc from "../../imagenes/Sidoc.png"
import TecnoQuimica from "../../imagenes/TecnoQuimica.png"
import Mondelez from "../../imagenes/Mondelez.png"


export default [
  {
    id: 1,
    imagen: UNal,
  },
  {
    id: 2,
    imagen: UnivalleEscuelaIngenieriaIndustrial,
  },
  {
    id: 3,
    imagen: UnivalleEscuelaIngenieriaElectricaElectronica,
  },
  {
    id: 4,
    imagen: Mondelez,
  },
  {
    id: 5,
    imagen: Celsia,
  },
  {
    id: 6,
    imagen: Sidoc,
  },
  {
    id: 7,
    imagen: TecnoQuimica,
  },
  {
    id: 8,
    imagen: BancoDeLaRepublicaColombia,
  }
];
