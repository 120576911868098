import LogoWhatsapp from '../iconos/WhatsApp.png'
import LogoLinkedin from '../iconos/Linkedin.png'

export default [
    {   
        id: 1,
        redireccion: "https://wa.me/573183566164?text=¿Necesitas%20ahorrar%20tiempo%20en%20la%20gestión%20de%20tesis%20y%20consolidar%20toda%20la%20información%3F",
        logo: LogoWhatsapp,
        mensaje: "Hablemos"
    },
    {   
        id: 2,
        redireccion: "https://www.linkedin.com/showcase/danalytics-education/",
        logo: LogoLinkedin,
        mensaje: "Conócenos"
    }
];
