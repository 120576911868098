import React from 'react';
import Logo from "../../imagenes/logo-education.png"
import './css/Header.css';


const Header = () => {

    return (
        <header className="header-main header-dark fixed-top header-fluid">
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container">
                    <div className="navbar-brand" >
                        <img className="logo-dark imagenLogoEducation" src={Logo} title="" alt="logo Danalytics Education" />
                        <img className="logo-light imagenLogoEducation" src={Logo} title="" alt="logoDanalyticsEducation" />
                    </div>
                    {/*
                    <button className="navbar-toggler ms-auto me-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    */}
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                </div>

            </nav>
        </header>
    );

}

export default Header