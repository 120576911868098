import './css/TarjetaUniversidad.css';
import React from 'react';


const TarjetaUniversidad = ({ informacion }) => {

    return (
        <div className="col-lg-4 col-sm-6 tarjetaUniversidad">
            <div className="carta">
                <div className="cuerpo">
                    <div className="cajaIlustracion">
                        <img title="" src={informacion.imagen} alt={"logoUniversidad" + informacion.id} />
                    </div>
                    <h3 className="titulo">{informacion.nombre}</h3>
                    {informacion.premios.map(premio => (
                        <div className="descripcion" key={premio.id}>{premio.descripcion}</div>
                    ))}
                </div>
            </div>
        </div>
    );

}

export default TarjetaUniversidad