import './css/TarjetaEstadistica.css';
import React from 'react';


const TarjetaEstadistica = ({ informacion }) => {

    return (
        <div className="col-lg-3 col-sm-6 tarjetaEstadistica">
            <div className="titulo">
                <span>{informacion.titulo}</span>
            </div>
            <p className="descripcion">{informacion.descripcion}</p>
        </div>
    );

}

export default TarjetaEstadistica